import React, { Component } from 'react';
import hbday from '../imgs/tianren.jpg';
import './twentyfirst.css';

export default class TwentyFirst extends Component {

    render(){
        return (
            <div>
                <img src={hbday} className="tianren-bday" alt="Tianren BDay" />
                <div class="button-container">
                    <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1QuDZ3YTVnk-lJWg3K-OznhvuDlK93JFq/view?usp=sharing" class="purple-button">Get Coupons</a>
                    <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/16VXXrEaxpj-jY2Ap-kVxSLuXu2dIcIK4/view?usp=sharing" class="f-purple-button">Extra Coupons</a>
                </div>
            </div>
        )
    }
}