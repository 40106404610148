import React, { Component } from 'react';

export default class Anniversary extends Component {
      
    render(){
        const divStyle = {
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
          };

        return (
            <div>
                <center style={divStyle}>
                    <h1>This is on the same floor as where we first met &#9829;</h1>
                </center>
            </div>
        )
    }
}