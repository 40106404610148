import React, { Component } from 'react';

export default class Homepage extends Component {

    render(){
        return (
            <div>
                <center>
                    <p>Welcome to parfei.com. This site is under construction. Come back for projects, blogs, and more!</p>
                </center>
            </div>
        )
    }
}