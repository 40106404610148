import React from 'react';
import { BrowserRouter as Router, Route} from "react-router-dom"; 
import './App.css';
import TwentyFirst from './components/twentyfirst.component';
import Homepage from './components/homepage.component';
import Anniversary from './components/anniversary.component';

function App() {
  return (
    <Router>
      <Route exact path="/" component={Homepage}/>
      <Route path="/twentyfirst" component={TwentyFirst}/>
      <Route path="/anniversary" component={Anniversary}/>
    </Router>
  );
}

export default App;
